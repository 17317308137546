import React, { useEffect, useState } from 'react';
import { navbarRef } from './HeaderComponent';
import { useRouter } from 'next/router';
import { useDispatch, useStore } from '@globalStore/storeProvider';

import axios from 'axios';
import { JOB_BOARD_COMPILER_BASE_URL } from '@lib/api';
import DemoClassFormButton from '@components/local/courses/forms/demo-class-form/DemoClassFormButton';

import cn from 'classnames';
import woodStyleClockIcon from '@assets/bytes/clock-front-color.png';
import Image from 'next/image';
import { DateTimeFormatter } from '@utils/utils';
import { Primary } from 'stories/Button.stories';
import { sourceType } from '../widgets/ContactUs';

function RevampedDemoClassStrip() {
  const [isStripOpen, setIsStripOpen] = useState(true);

  const { pathname } = useRouter();

  const { nextCohort } = useStore();

  const dispatch = useDispatch();

  const headerPosition =
    typeof window !== undefined &&
    window?.getComputedStyle?.(navbarRef.current)?.getPropertyValue('position');

  const closeStripBanner = () => {
    setIsStripOpen(false);
    dispatch({ type: 'SET_STRIP_BANNER', payload: false });
  };

  useEffect(() => {
    dispatch({ type: 'SET_STRIP_BANNER', payload: true });
  }, []);

  // ... existing code ...
  const isTodayTomorrowOrYesterday = (dateString) => {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    return (
      date.toDateString() === today.toDateString() ||
      date.toDateString() === tomorrow.toDateString() ||
      date.toDateString() === yesterday.toDateString()
    );
  };
  // ... existing code ...
  const isYesterday = (dateString) => {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    return date.toDateString() === yesterday.toDateString();
  };
  // ... existing code ...
  const getCohortStartDateMessage = (startDate) => {
    if (!startDate) return 'Limited Seats Available';

    const formattedDate = DateTimeFormatter(startDate, '', true);

    if (isYesterday(startDate)) {
      return `started ${formattedDate}`;
    }

    if (isTodayTomorrowOrYesterday(startDate)) {
      return `starts ${formattedDate}`;
    }

    return `starts on ${formattedDate}`;
  };
  const DownloadPlacementReport = ({ isMobile = false }) => {
    const clickHandler = () =>
      dispatch({ type: 'SET_WIDGET_SOURCE', payload: sourceType.placement_report });

    return (
      <>
        <Primary
          onClick={clickHandler}
          size={'small'}
          label={isMobile ? 'Placement Report' : 'Download Placement Report'}
        />
      </>
    );
  };

  return (
    <>
      {isStripOpen && (
        <>
          {/* Dektop */}
          <section className="sticky top-[4rem] z-40 hidden h-[4rem] w-full items-center justify-evenly bg-gradient-to-r from-[#FFE843] to-[#FFAD62] font-satoshi-medium text-[14px] font-medium leading-5 md:flex md:h-14 md:justify-center md:py-2 md:pr-0 md:text-sm">
            <div className="flex items-center justify-center gap-2">
              <div className={'flex items-center gap-2'}>
                {nextCohort ? (
                  <>
                    <img src="/assets/strips/arrow.png" alt="" className="ml-2 h-6" />
                    <p className="self-start font-satoshi-bold text-[18px] leading-6">
                      Book a live demo session
                    </p>
                  </>
                ) : (
                  <>
                    <img src="/assets/strips/rocket2.png" alt="" className="ml-2 h-8" />
                    <p className="self-start font-satoshi-bold text-[18px] leading-8">
                      Your Success, Our Mission!
                    </p>
                  </>
                )}
              </div>

              <div className={'flex'}>
                {nextCohort ? (
                  <div className="flex items-center gap-2">
                    <img src="/assets/strips/clock.png" alt="" className="h-6" />
                    <p className="font-satoshi-medium text-[14px] leading-5">
                      Next cohort {getCohortStartDateMessage(nextCohort?.startDate)}
                    </p>
                  </div>
                ) : (
                  <div className="flex items-center gap-2">
                    <img src="/assets/strips/dart.png" alt="" className=" h-8" />
                    <p className="font-satoshi-medium">781 Careers Launched in 2024 – Be Next!</p>
                  </div>
                )}
              </div>

              <div className={''}>
                {nextCohort ? (
                  <DemoClassFormButton
                    label={'Book Now'}
                    centerModal
                    size="small"
                    capitalCase={false}
                  />
                ) : (
                  <DownloadPlacementReport />
                )}
              </div>

              {/* <button
              className="absolute place-self-end self-start font-satoshi-bold text-2xl !leading-3 text-white md:text-3xl"
              onClick={closeStripBanner}
            >
              &times;
            </button> */}
            </div>
          </section>
          {/* Mobile */}
          <div className="sticky top-[4rem] z-40 grid h-auto w-full grid-rows-[1fr] bg-[url('/assets/strips/mob-gradient.png')] bg-cover p-2  md:hidden">
            <div className="flex items-center gap-2">
              <div className="grid place-items-center">
                {/* Image Line Cohort */}
                {nextCohort ? (
                  <img
                    src="/assets/strips/clock.png"
                    alt=""
                    className="mr-2 flex h-[3rem] items-center justify-center"
                  />
                ) : (
                  <img
                    src="/assets/strips/rocket2.png"
                    alt=""
                    className="flex h-[3rem] items-center justify-center "
                  />
                )}
              </div>
              {/* 1st Line Cohort */}
              <div className="flex flex-1 flex-col gap-0">
                {nextCohort ? (
                  <p className="self-start font-satoshi-bold text-[18px] ">
                    Book a live demo session
                  </p>
                ) : (
                  <p className="self-start font-satoshi-bold text-[18px] ">
                    Your Success, Our Mission!
                  </p>
                )}
                {/* 2nd Line Cohort */}
                {nextCohort ? (
                  <p className="font-satoshi-medium text-[12px] leading-5">
                    Next cohort {getCohortStartDateMessage(nextCohort?.startDate)}{' '}
                  </p>
                ) : (
                  <p className="self-start font-satoshi-medium text-[16px] leading-tight">
                    781 Careers Launched in 2024.
                  </p>
                )}
              </div>
              {/* Download Placement Report */}
              <div className={'flex items-center justify-center'}>
                {nextCohort ? (
                  <DemoClassFormButton
                    label={'Book Now'}
                    centerModal
                    size="small"
                    capitalCase={false}
                  />
                ) : (
                  <div className="flex  items-center justify-center">
                    <DownloadPlacementReport isMobile={true} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default RevampedDemoClassStrip;

async function fetchUpcomingDemoClass() {
  const {
    data: { upcomingClass },
  } = await axios.get(`${JOB_BOARD_COMPILER_BASE_URL}/api/website/demo-class/upcoming`);

  return upcomingClass;
}
